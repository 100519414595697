/* global COMSCORE */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import CardListItem from 'components/card-list-item';

let currentScroll = 0;

export default class LoadMore extends Component {
  static propTypes = {
    baseUrl: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
    totalPages: PropTypes.string.isRequired,
    currentPage: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);

    this.loadNextPage = this.loadNextPage.bind(this);
    this.state = {
      articles: [],
      currentPage: parseInt(props.currentPage, 10),
      finished: false,
      loading: false,
      totalPages: parseInt(props.totalPages, 10),
      failoverImage: [],
      partnerLogo: '',
    };
  }

  componentDidUpdate() {
    if (! this.state.loading) {
      window.scroll(0, currentScroll);
    }
  }

  /**
   * Retrieve the next page URL.
   *
   * @return {String}
   */
  getNextPageUrl() {
    const { baseUrl } = this.props;
    const { currentPage } = this.state;
    return wp.url.addQueryArgs(baseUrl, { page: currentPage + 1 }); // eslint-disable-line max-len
  }

  /**
   * Callback to load next page.
   */
  loadNextPage(e) {
    const {
      articles,
      currentPage,
      finished,
      loading,
    } = this.state;
    if (finished || loading) {
      return;
    }
    currentScroll = window.pageYOffset;
    e.preventDefault();
    this.setState({ loading: true });

    fetch(this.getNextPageUrl())
      .then((response) => response.json())
      .then((response) => {
        const newArticles = response.template_items.items || [];
        const pageNow = currentPage + 1;
        this.setState({
          articles: [
            ...articles,
            ...newArticles,
          ],
          currentPage: pageNow,
          failoverImage: response.station_assets.failover_image || [],
          partnerLogo:
            response.station_assets.partner_branding.branding_image_alt || '',
          finished: 0 === newArticles.length,
          loading: false,
        });
      });

    // eslint-disable-next-line no-unused-expressions
    window.COMSCORE && COMSCORE.beacon({ c1: '2', c2: '6035083' });
  }

  render() {
    const { buttonText } = this.props;
    const {
      articles,
      finished,
      loading,
      currentPage,
      totalPages,
      partnerLogo,
      failoverImage,
    } = this.state;
    const loadingClass = loading ? ' loading' : '';
    const urlhref = ('undefined' !== typeof nbc.dataLayer.link) ?
      `${nbc.dataLayer.link}?page=${currentPage + 1}` :
      '';

    const list = () => (
      <ul className="content-list">
        {articles.map((article) => (
          <CardListItem
            item={article}
            key={article.link}
            partnerLogo={partnerLogo}
            failoverImage={failoverImage}
          />
        ))}
      </ul>
    );

    const footer = () => (
      <footer className="content-list-footer">
        {/* eslint-disable jsx-a11y/anchor-is-valid */}
        {/* eslint-disable jsx-a11y/click-events-have-key-events */}
        {/* eslint-disable jsx-a11y/no-static-element-interactions */}
        <a
          href={urlhref}
          className={`content-list-button button${loadingClass}`}
          disabled={loading}
          onClick={(e) => {
            this.loadNextPage(e);
          }}
          rel="next"
        >
          <span className="button-text more-news__show-more-text">
            {buttonText}
          </span>
        </a>
      </footer>
    );
    if (totalPages && currentPage >= totalPages) {
      return (list());
    }

    if (articles.length && ! finished) {
      return (
        <Fragment>
          {list()}
          {footer()}
        </Fragment>
      );
    }

    if (articles.length) {
      return list();
    }

    if (! finished) {
      return footer();
    }

    return null;
  }
}
