import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';

class ShowMoreText extends Component {
  static defaultProps = {
    expanded: 'false',
    children: '',
  };

  static propTypes = {
    expanded: PropTypes.string,
    children: PropTypes.node,
  };

  state = {
    expanded: false,
  };

  render() {
    const {
      children,
    } = this.props;

    let { expanded } = this.state;

    if (! expanded && 'true' === this.props.expanded) {
      // allow prop override.
      expanded = true;
    }

    return (
      <span className="truncate__wrap">
        <span className="truncate__text">
          {parse(DOMPurify.sanitize(children))}
        </span>
      </span>
    );
  }
}

export default ShowMoreText;
