/**
 * Archive global JS.
 */

import domContentLoaded from 'utils/domContentLoaded';
import reactComponentManager from 'utils/reactComponentManager';

// Components
import LoadMore from 'components/load-more';
import NBCTruncate from 'components/nbc-truncate';

// Import SCSS
import '../../scss/archive/index.scss';

domContentLoaded(() => {
  reactComponentManager({
    LoadMore,
    NBCTruncate,
  });
});

if (module.hot) {
  module.hot.accept();

  reactComponentManager({
    LoadMore,
    NBCTruncate,
  });
}

const sectionMenu = document.querySelector('.section-menu__item-link.active');
if (sectionMenu) {
  sectionMenu.addEventListener('click', (e) => {
    if (1280 > document.documentElement.clientWidth) {
      document.getElementsByClassName('section-menu')[0]
        .classList.toggle('expanded');
    }
    e.preventDefault();
    return false;
  });
}
