import {
  identityAuthSubscribe,
} from './utils';

const {
  nbc: {
    dataLayer: {
      contextData,
      pageName,
    },
  },
} = window;

// #region Prop/evars we want to track when a link is clicked.
const linkTrackVars = [
  'prop1',
  'prop2',
  'prop3',
  'prop4',
  'prop8',
  'prop9',
  'prop10',
  'prop20',
  'prop21',
  'prop22',
  'prop23',
  'prop48',
  'prop52',
  'prop53',
  'prop54',
  'prop55',
  'eVar123',
  'contextData.actionmodule',
  'contextData.actionname',
  'contextData.identityauth',
];

/**
 * #region Track a click event for Unified components.
 *
 * @param {String} actionName (required) Action name that was clicked.
 * @param {String} actionModule (required) Module name from where the action link was clicked from.
 * @param {String} noid (optional) Network Object ID of the content.
 */
const unifiedTrackClick = (actionName: string, actionModule: string, noid?: string) => {
  // eslint-disable-next-line no-console
  console.log('unifiedTrackClick triggered: ', actionName, actionModule);

  if ('undefined' === typeof window.s) { // sometimes s object isnt available, so we need to check with window.s
    return;
  }

  // #region Save the current linkTrackVars and linkTrackEvents.
  const {
    linkTrackVars: linkTrackVarsCopy,
    linkTrackEvents: linkTrackEventsCopy,
  } = window.s;

  // #region Get the current identity auth status.
  let identityAuthValue = 'unknown';

  identityAuthSubscribe((status) => {
    if (['unauthenticated', 'authenticated'].includes(status)) {
      identityAuthValue = status;
      contextData.identityauth = identityAuthValue;
    }
  });

  // #region Declare which props/evars we want to track.
  window.s.linkTrackVars = linkTrackVars.join(',');
  window.s.linkTrackEvents = '';

  // #region Set props and contexual vars.
  const trackVars = {
    prop21: noid,
    prop52: pageName,
    prop53: actionName,
    prop54: `${pageName} | ${actionName}`,
    prop55: actionModule,
    eVar123: identityAuthValue,
    contextData: {
      actionmodule: actionModule,
      actionname: actionName,
      identityauth: identityAuthValue,
    },
  };

  // #region Track the link.
  window.s.tl(this, 'o', actionModule, trackVars);

  // #region Reset linkTrackVars and linkTrackEvents.
  window.s.linkTrackVars = linkTrackVarsCopy;
  window.s.linkTrackEvents = linkTrackEventsCopy;
};

export default unifiedTrackClick;
