/* eslint-disable no-restricted-globals */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextTruncate from 'react-text-truncate';
import ShowMoreText from './ShowMoreText';
import './nbc-truncate.scss';

const {
  i18n: {
    __,
  },
} = wp;

// Breakpoints for changing truncate behavior.
const BREAKPOINT = 600;

/**
 * Calculate number of lines to display.
 * @param {Number} maxLinesDesktop The number of lines to use on desktop breakpoints.
 * @param {Number} maxLinesMobile The number of lines to use on mobile breakpoints.
 * @return {Number}
 */
export function getNumLines(maxLinesDesktop, maxLinesMobile) {
  // eslint-disable-next-line max-len
  return parseInt(window.innerWidth < BREAKPOINT ? maxLinesMobile : maxLinesDesktop, 10);
}

/**
 * Truncate component.
 */
export default class NBCTruncate extends Component {
  static propTypes = {
    expanded: PropTypes.string,
    maxlines: PropTypes.string.isRequired,
    maxlinesmobile: PropTypes.string.isRequired,
    readmore: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    ellipsis: PropTypes.string,
    readLess: PropTypes.string,
  };

  /**
   * Set initial props.
   * @type {object}
   */
  static defaultProps = {
    expanded: 'false',
    ellipsis: 'false',
    readLess: __('Read less', 'nbc'),
  };

  render() {
    const {
      maxlines,
      maxlinesmobile,
      readmore,
      readLess,
      text,
      ellipsis,
      expanded,
    } = this.props;

    const numlines = getNumLines(maxlines, maxlinesmobile);
    const escapedtext = text.toString().replace(/\\\/|\/\\/g, '/');

    if ('true' !== ellipsis) {
      return (
        <ShowMoreText
          expanded={expanded}
          lines={numlines}
          more={readmore}
          less={readLess}
          anchorClass="readmore"
          onClick={this.executeOnClick}
        >
          {escapedtext}
        </ShowMoreText>
      );
    }
    return (
      <TextTruncate
        element="span"
        line={parseInt(maxlines, 10)}
        truncateText="..."
        text={text}
      />
    );
  }
}
