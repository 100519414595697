import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';
import NBCTruncate from 'components/nbc-truncate';
import { identityBookmarkAction } from '../unified-identity/utils';
import unifiedTrackClick from '../unified-identity/tracking';
import formatSyndicatedID from '../nbc-jw-player/utils';

const {
  i18n: {
    __,
    sprintf,
  },
} = wp;

/**
 * Card List Item
 *
 * Matches the server-side template: template-parts/card-list-item.php
 */
function CardListItem(props) {
  const { item, failoverImage, partnerLogo, isDashboardFeed, noids = [], updateNoids } = props;
  const { sponsored } = item.eyebrow;
  const sponsoredClass = sponsored ? 'story-card__meta--sponsored' : '';
  const { name: sponsorName, dart_pixel: pixel } = item.sponsor;
  const { thumbnail = '', noid = '' } = item || {};
  // const imageUrl = ! Array.isArray(item.image.ratios) ?
  //   item.image.ratios['16:9']['850'] || failoverImage.ratios['16:9']['850'] :
  //   failoverImage.ratios['16:9']['850']

  const [isSaved, setIsSaved] = useState(noids.includes(noid));
  const [isButtonDisabled, setButtonDisabled] = useState(false);

  useEffect(() => {
    setIsSaved(noids.includes(noid));
  }, [noids]);

  const {
    image: {
      ratios: {
        '16:9': {
          850: image = '',
        } = {},
      } = {},
    } = {},
    excerpt,
    title,
    type: itemType,
  } = item || {};

  const {
    ratios: {
      '16:9': {
        850: failover = '',
      } = {},
    } = {},
  } = failoverImage || {};

  const getAltText = (imageObj) => {
    const {
      caption,
      caption: {
        rendered = '',
      } = {},
      alt_text: altText = '',
    } = imageObj || {};
    if (altText) {
      return altText;
    } else if (caption && rendered) {
      const doc = new DOMParser().parseFromString(rendered, 'text/html');
      return doc.body.textContent ? doc.body.textContent.trim() : '';
    } else {
      return '';
    }
  };

  const imageUrl = ! image ? failover : image;
  const altText = getAltText(item.image);
  const hasNoTag = ! item.eyebrow.label && 'noTag';

  return (
    <li className="story-card
      story-card__list-item story-card__list-item--post-river"
    >
      <a
        className={`story-card__thumbnail ${isDashboardFeed && 'isDashboardFeed'}`}
        href={item.link}
        data-trackingtext={parse(DOMPurify.sanitize(title))}
      >
        <div className="imagewrap1">
          <div className="imagewrap2">
            <img
              loading="lazy"
              src={imageUrl || thumbnail}
              alt={altText ? parse(DOMPurify.sanitize(altText)) : ' '}
            />
            {item.icon && (
              <span className={`format-icon format-icon--${item.icon}`} />
            )}
            {(isDashboardFeed && ('video' === itemType || item.has_lede_video)) && (
              <span className="dashboard-video-icon" />
            )}
            {(isDashboardFeed && 'video' === itemType) && (
              <>
                <span className="dashboard-video-icon" />
                <span className="dashboard-video-length">{item.video_duration}</span>
              </>
            )}
          </div>
        </div>
        {'CNBC' === partnerLogo && (
          <div className="cnbc_logo">
            <img src="https://fm.cnbc.com/applications/cnbc.com/resources/styles/skin/cnbc_white_shadow_ots.png" alt="CNBC" />
          </div>
        )}
      </a>
      <div className={`story-card__text ${isDashboardFeed && 'isDashboardFeed'}`}>
        <div className={`story-card__meta ${isDashboardFeed && 'isDashboardFeed'} ${hasNoTag && 'noTag'} ${sponsoredClass}`}>
          {item.eyebrow.label && (
            <span className={`story-card__meta-tag ${isDashboardFeed && 'isDashboardFeed'}`}>
              <a
                className="story-card__meta-tag-link"
                href={parse(DOMPurify.sanitize(item.eyebrow.link))}
              >
                {parse(DOMPurify.sanitize(item.eyebrow.label))}
              </a>
            </span>
          )}
          {isDashboardFeed && (
            <>
              {! sponsored && (
                <span className={`story-card__meta-time ${isDashboardFeed && 'isDashboardFeed'}`}>
                  {parse(DOMPurify.sanitize(item.publish_human))}
                </span>
              )}
              <button
                className={`story-card__meta identity-profile__is${isSaved ? 'Saved' : 'Unsaved'}`}
                disabled={isButtonDisabled}
                onClick={() => {
                  setButtonDisabled(true);
                  identityBookmarkAction(noid, isSaved, (res) => {
                    setIsSaved(res && ! isSaved);
                    setButtonDisabled(false);
                  }, updateNoids);
                  unifiedTrackClick(`${'video' === itemType ? 'video - ' : 'article - '}${formatSyndicatedID(noid)}`, `item ${isSaved ? 'unsave' : 'save'} bar`);
                }}
                type="button"
              >BOOKMARK
              </button>
            </>
          )}
          {parse(DOMPurify.sanitize(pixel))}
          {! sponsored && (
            <span className="story-card__meta-time">
              {parse(DOMPurify.sanitize(item.date_string))}
            </span>
          )}
        </div>
        <h3 className={`story-card__title ${isDashboardFeed && 'isDashboardFeed'}`}>
          <a
            className={`story-card__title-link ${isDashboardFeed && 'isDashboardFeed'}`}
            href={parse(DOMPurify.sanitize(item.link))}
          >
            {parse(DOMPurify.sanitize(title))}
          </a>
        </h3>
        {item.summary &&
          <div className="story-card__excerpt">
            <NBCTruncate
              maxlines="5"
              maxlinesmobile="5"
              text={DOMPurify.sanitize(item.summary)}
              ellipsis="false"
              readmore=""
            />
          </div>
        }
        {isDashboardFeed && (
          <div className={`story-card__excerpt ${isDashboardFeed && 'isDashboardFeed'}`}>
            <NBCTruncate
              maxlines="5"
              maxlinesmobile="5"
              text={DOMPurify.sanitize(excerpt)}
              ellipsis="false"
              readmore=""
            />
          </div>
        )}

        {(! sponsored && ! isDashboardFeed) && (
          <div
            className="share-buttons"
            data-component="sharingBar"
          >
            <a
              href={parse(DOMPurify.sanitize(item.share_links.facebook))}
              data-service="facebook"
              target="_blank"
              rel="noreferrer"
              aria-label="Share on Facebook"
            >
              <span className="icon icon-facebook-f" />
            </a>
            {/* <a
              href={parse(DOMPurify.sanitize(item.share_links.twitter))}
              data-service="twitter"
              target="_blank"
              rel="noreferrer"
            >
              <span className="icon icon-twitter" />
            </a> */}
            <a
              href={parse(DOMPurify.sanitize(item.share_links.email))}
              data-service="email"
              target="_blank"
              rel="noreferrer"
              aria-label="Share with Email"
            >
              <span className="icon icon-envelope" />
            </a>
          </div>
        )}
        {sponsored && (
          <div className="story_card__presented_by">
            {
              sprintf(
                /* translators: 1: sponsor name */
                `${__('Promoted By', 'nbc')} %s`,
                /* eslint-enable max-len */
                sponsorName,
              )
            }
          </div>
        )}
      </div>
    </li>

  );
}

CardListItem.defaultProps = {
  isDashboardFeed: false,
  noids: [],
  updateNoids: () => {},
};

CardListItem.propTypes = {
  item: PropTypes.shape({}).isRequired,
  failoverImage: PropTypes.shape({}).isRequired,
  partnerLogo: PropTypes.string.isRequired,
  isDashboardFeed: PropTypes.bool,
  noids: PropTypes.array,
  updateNoids: PropTypes.func,
};

export default CardListItem;
